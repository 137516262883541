/* Add this to your CSS file or inside a <style> tag */
body {
    background-color: #f0f0f0; /* Light gray background for the whole page */
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
.space-before {
    margin-top: 2rem; /* Adjust the value as needed */
  }

  .container {
    background-color: #ffffff; /* White background for the container */
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Subtle shadow for depth */
    padding: 20px; /* Space inside the container */
    margin: 20px; /* Space around the container */
  }
.info.legend {
    background: white;
    padding: 6px 8px;
    font: 14px Arial, Helvetica, sans-serif;
    border-radius: 5px;
}
.selected-state-info {
    width: 100%;
    background-color: white;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

.info.legend i {
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.info.legend .labels {
    margin: 0;
    padding: 0;
    list-style: none;
}
.map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-group {
    margin: 0 10px;
}

.filter-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.filter-group select {
    padding: 5px;
    width: 150px;
}

.button-group {
    display: flex;
    align-items: center;
}

.button-group button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.button-group button:hover {
    background-color: #0056b3;
}

.color-scale-bar {
    background: rgba(255, 255, 255, 0.8);
    padding: 8px;
    font-size: 14px;
    
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.donut-chart-container {
    margin-top: 20px;
    width: 400px;
    height: 400px;
}

.donut-chart-container h3 {
    text-align: center;
    margin-bottom: 10px;
}
/* src/pages/MapView.css */

.map-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chart-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .info-button {
    margin-left: 20px; /* Adjust margin as needed */
    padding: 10px 20px;
    background-color: #007bff; /* Change background color */
    color: white; /* Change text color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .info-button:hover {
    background-color: #0056b3; /* Change background color on hover */
  }
  
  /* YourComponent.css */
.map-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  
/* .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px; 
    margin-top: 20px;
    overflow: hidden; 
  } */
  
.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content horizontally */
  }
.slide-out {
  transform: translateX(-100%);
}

.slide-in {
  transform: translateX(0);
}

.chart-container {
  transform: translateX(100%);
}

.chart-container.slide-in {
  transform: translateX(0);
}
