.chatbot-container {
    position: fixed;
    bottom: 30px;
    right: 40px;
    z-index: 1000;
}

.chatbot-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #c6e1ff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3); /*shadow*/
    outline: 5px solid rgb(66, 154, 255);
    border-radius: 50%;
}

.chatbot-icon img {
    width: 60px;
    height: 60px;
}

.chatbot-window {
    width: 300px;
    height: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    opacity: 100;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.chatbot-header {
    background-color: #0056b3;
    color: white;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    max-width: 80%;
}

.bot-message {
    background-color: #f1f1f1;
    color: black;
    align-self: flex-start;
    margin-right: auto;
    padding: 15px;
}

.predefined-questions-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
}

.predefined-question {
    cursor: pointer;
    padding: 5px 0;
    transition: color 0.3s ease, padding-left 0.3s ease;
}

.predefined-question:hover {
    background-color: #e6f2ff;
    color: #0056b3;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 5px;
}

.chatbot-body {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    border-top: 1px solid #ddd;
    scrollbar-width: thin;
    scrollbar-color: #007bff #f1f1f1;
}

.chatbot-body::-webkit-scrollbar {
    width: 6px;
}

.chatbot-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chatbot-body::-webkit-scrollbar-thumb {
    background-color: #0056b3;
    border-radius: 10px;
}

.chatbot-form {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
}

.chatbot-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    margin-right: 10px;
    max-height: 80px;
    overflow-y: auto;
    resize: none;
}

.chatbot-form button {
    padding: 10px 15px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chatbot-form button:hover {
    background-color: #0056b3;
}