/* Reset some default styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f8f8f8;
}

.hero-background {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.hero-section {
  padding: 60px 20px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  background-image: url('../../public/bg4.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 30px;
    text-align: center;
    background-size: cover;
    background-position: center;
  }

  .hero-section h1 {
    font-size: 3rem; 
    font-weight: bold;
  }

  .hero-section p {
    font-size: 1.5rem; 
  }

  .hero-section button {
    font-size: 1rem; 
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 20px;
    background-size: cover;
    background-position: center;
  }

  .hero-section h1 {
    font-size: 1.5rem; 
  }

  .hero-section p {
    font-size: 0.9rem; 
  }

  .hero-section button {
    font-size: 0.9rem; 
    padding: 8px 16px;
  }
}

.content-section {
  background-color: #FFF4D4; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  gap: 50px; 
  padding: 40px;
  flex-wrap: wrap; 
  margin-top: 0;
}

@media (max-width: 1024px) {
  .content-section {
    grid-template-columns: repeat(3, 1fr); 
    gap: 30px; 
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .content-section {
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .content-section {
    grid-template-columns: 1fr; 
    gap: 15px;
    padding: 10px;
  }

  .card {
    max-width: 100%; 
    margin-bottom: 20px;
  }
}

.card {
  background-color: #FFFFFF; 
  border: 1px solid #E0E0E0; 
  padding: 20px;
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-align: left; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none; 
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; 
  max-width: 250px; 
  height: 85%; 
  margin-bottom: 20px; 
}

@media (max-width: 768px) {
  .card {
    max-width: 90%; 
    margin: 0 auto 20px; 
  }

  .card-icon {
    font-size: 50px; 
  }

  .card h3 {
    font-size: 1.2rem; 
  }

  .card p {
    font-size: 0.9rem; 
  }
}

@media (max-width: 480px) {
  .card {
    max-width: 100%; 
    margin-bottom: 15px;
    padding: 15px; 
  }

  .card-icon {
    font-size: 40px; 
  }

  .card h3 {
    font-size: 1rem; 
  }

  .card p {
    font-size: 0.8rem; 
  }
}

.card-icon {
  font-size: 80px; 
  color: #0F3877; 
  margin-bottom: 15px;
  text-align: center; 
}

.card h3 {
  margin: 15px 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.card hr {
  border: none;
  border-top: 2px solid #E0E0E0; 
  margin: 20px 0; 
  width: 80%; 
  margin-left: auto;
  margin-right: auto;
}

.card p {
  margin: 0;
  font-size: 1rem;
  color: #555;
}

.card::after {
  content: '\f061'; 
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  color: #005BBB; 
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}


.empowerment-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.empowerment-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.empowerment-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.empowerment-section li {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

.more-info-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0F3877;
  color: white;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.more-info-btn:hover {
  background-color: #0056b3;
}


.get-started-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0F3877; 
  color: white; 
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.get-started-btn:hover {
  background-color: #0056b3; 
}

.about-section, .empowerment-section {
  padding: 10px 10px;  
  margin: 0 auto;  
  max-width: 1200px; 
  background-color: transparent;
}

@media (max-width: 768px) {
  .about-section, .empowerment-section {
    padding: 20px 10px;
    background-color: transparent;
  }
}

@media (max-width: 480px) {
  .about-section, .empowerment-section {
    padding: 15px 10px;
    background-color: transparent;
  }
}
