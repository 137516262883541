.scam-dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .dashboard-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 80px;
  }
  
  .dashboard-description {
    font-size: 1.1rem;
    text-align: left;
    margin-bottom: 30px;
    color: #555;
  }
  
  .dashboard-content {
    margin-bottom: 50px !important;
  }
  
  .why-this-matters {
    background-color: #f9f9f9!important;
    padding: 20px !important;
    border-radius: 8px !important;
  }
  
  .why-this-matters h2 {
    font-size: 1.5rem !important;
    margin-bottom: 10px !important;
  }
  
  .why-this-matters ul {
    margin-left: 20px !important;
    list-style-type: disc !important;
  }
  
  .why-this-matters p {
    font-size: 1rem !important;
    color: #333 !important;
  }
  